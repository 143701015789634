import React from 'react';
import { useState, useEffect } from 'react';
// import loginImgPng from '../../../assets/images/auth/login-image.png';
import SideMenu from './components/SideMenu';
import ChatBox from './components/ChatBox';
import '../../../normal.css';
import '../../../App.css';
import { BACKEND_API_URL, OPENAI_SERVER } from '../../../components/utils/constants/misc';
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { Link, useNavigate } from "react-router-dom";
import { showMsg } from '../../../components/utils/general';
import { logout } from '../../../features/user/userActions';
const ChatGptOpenAi = () => {
  useEffect(() => {
    getEngines();
  }, [])

  const [chatInput, setChatInput] = useState("");
  const [models, setModels] = useState([]);
  const [temperature, setTemperature] = useState(0.5);
  const [currentModel, setCurrentModel] = useState("text-davinci-003");
  const [chatLog, setChatLog] = useState([{
    user: "gpt",
    message: "How can I help you today?"
  }]);
  const [userState, setUserState] = useState({
    checkBoxIsUseOperandAI: false,
    txtBasePrompt: "",
    txtIndexId: ""
  });
  const [isLoading, setIsLoading] = useState(false);


  const dispatch = useDispatch();
  const navigate = useNavigate();

  // clear chats
  function clearChat() {
    setChatLog([]);
  }

  function getEngines() {
    fetch(`${BACKEND_API_URL}${OPENAI_SERVER}/models`)
      .then(res => res.json())
      .then(data => {
        console.log(data.models.data)
        // set models in order alpahbetically
        data.models.data.sort((a, b) => {
          if (a.id < b.id) { return -1; }
          if (a.id > b.id) { return 1; }
          return 0;
        })
        setModels(data.models.data)
      })
  }

  async function handleSubmit(e) {
    e.preventDefault();
    let chatLogNew = [...chatLog, { user: "me", message: `${chatInput}` }]
    setChatInput("");
    setChatLog(chatLogNew)
    // fetch response to the api combining the chat log array of messages and seinding it as a message to localhost:3000 as a post
    const messages = chatLogNew.map((message) => message.message).join("\n")

    const response = await fetch(`${BACKEND_API_URL}${OPENAI_SERVER}/send`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        message: messages,
        currentModel,
        userState,
      })
    });
    const data = await response.json();
    setChatLog([...chatLogNew, { user: "gpt", message: `${data.message}` }])
    var scrollToTheBottomChatLog = document.getElementsByClassName("chat-log")[0];
    scrollToTheBottomChatLog.scrollTop = scrollToTheBottomChatLog.scrollHeight;
  }

  function handleTemp(temp) {
    if (temp > 1) {
      setTemperature(1)
    } else if (temp < 0) {
      setTemperature(0)
    } else {
      setTemperature(temp)
    }

  }

  const btnOnClickLogout = () => {
    
    const objPostData = {};
    setIsLoading(true);
    dispatch(logout(objPostData))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsLoading(false);
        console.log("originalPromiseResult in logout");
        console.log("originalPromiseResult in logout");
        console.log("originalPromiseResult in logout");
        console.log(originalPromiseResult);
        const response = originalPromiseResult;

        if (response.error === false) {
          showMsg("success", "You have been successfully logged out.");
          let redirectUrl = "/login";
          navigate(redirectUrl);
        } else {
          showMsg("error", "Ops something went wrong, Please try again");
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsLoading(false);
        showMsg("error", "Ops something went wrong, Please try again");

      });
  };
  const btnOnClickLogin = () => {
    navigate("/login");
  };

  const handleInputChange = (event,fieldName, inputType) => {
    let value;
    // let { value } = event.target;
    console.log('event')
    console.log('event')
    console.log(event)
    if(inputType === 'checkbox'){
      value = event.target.checked;
    }else{
      value = event.target.value;
    }
    setUserState((prevProps) => ({
      ...prevProps,
      [fieldName]: value
    }));
  };
  console.log('userState in render');
  console.log('userState in render');
  console.log(userState);
  return (
    <>
      <div className="App">
        <SideMenu
          currentMode={currentModel}
          setCurrentModel={setCurrentModel}
          models={models}
          setTemperature={handleTemp}
          temperature={temperature}
          clearChat={clearChat}
          btnOnClickLogin={btnOnClickLogin}
          btnOnClickLogout={btnOnClickLogout}
          userState={userState}
          setUserState={setUserState}
          handleInputChange={handleInputChange}
        />
        <ChatBox
          chatInput={chatInput}
          chatLog={chatLog}
          setChatInput={setChatInput}
          handleSubmit={handleSubmit} />
      </div>

    </>
  );
};

export default ChatGptOpenAi;
