import React, { Fragment, useState } from 'react';
import axios from 'axios';
import { Row, Col, Form, Label, FormGroup, Button, Input } from 'reactstrap';

const ForgotPasswordForm = (props) => {
    const { 
        formRef, 
        urlParams,
    } = props;
    
   
    
    return (
        
                <Form>
                    <h4 className="text-theme fw-600 fs-32 mb-30">Forget Password</h4>
                    <Row>
                        <Col>
                            <FormGroup >
                                <Label className="text-theme">Email Address</Label>
                                <Input
                                    type="email"
                                    name="email"
                                    id="email"
                                    placeholder="Email"
                                />
                            </FormGroup>
                            
                            
                            
                            
                            <FormGroup className="mt-30">
                                <Button className="btn-second btn-submit full-width btn btn-success btnLogin">
                                    Reset Now
                                </Button>
                            </FormGroup>

                            

                            
                            
                          
                            
                            
                                
                            
                        </Col>
                    </Row>
                </Form>
           
    );
};

export default ForgotPasswordForm;
