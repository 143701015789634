import React from 'react';
const NoAuthLayoutFooter = () => {
    return (
        <>
            <div className='copyrights-area copyrights-area-no-auth'>
                <div className='text-center'>
                    <p>
                        Copyright 2023 Yourxpert.ai LLC.
                    </p>
                    
                </div>
            </div>
        </>
    );
};

export default NoAuthLayoutFooter;