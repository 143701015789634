import React, { Fragment, useState } from 'react';
import axios from 'axios';
import { Row, Col, Form, Label, FormGroup, Button, Input } from 'reactstrap';

const ResetPasswordForm = (props) => {
    const { 
        formRef, 
        urlParams,
    } = props;
    
   
    
    return (
        
                <Form>
                    <h4 className="text-theme fw-600 fs-32 mb-30">Reset Your Password</h4>
                    <Row>
                        <Col>
                            <FormGroup >
                                <Label className="text-theme" for="password">Password</Label>
                                {/* <input type="password" id="password" name="password" className="form-control"  placeholder="Enter Your Password" required /> */}
                                <Input
                                    type="password"
                                    name="password"
                                    id="password"
                                    placeholder="Password"
                                    
                                />
                                
                            </FormGroup>

                            <FormGroup >
                                <Label className="text-theme" for="password">Confirm Password</Label>
                                {/* <input type="password" id="password" name="password" className="form-control"  placeholder="Enter Your Password" required /> */}
                                <Input
                                    type="password"
                                    name="confirmPass"
                                    id="confirmPass"
                                    placeholder="Confirm Password"
                                    
                                />
                                
                            </FormGroup>
                            
                            
                            
                            
                            <FormGroup className="mt-30">
                                <Button className="btn-second btn-submit full-width btn btn-success btnLogin">
                                    Reset Password
                                </Button>
                            </FormGroup>

                            

                            
                            
                          
                            
                            
                                
                            
                        </Col>
                    </Row>
                </Form>
           
    );
};

export default ResetPasswordForm;
