const SideMenu = (props) => {
  const { 
    clearChat, currentModel, setCurrentModel, models, setTemperature, 
    temperature, btnOnClickLogin, btnOnClickLogout, 
    userState, setUserState, handleInputChange
  }
  = props;
  return (
    <aside className="sidemenu">
      <div className="side-menu-button" onClick={clearChat}>
        <span>+</span>
        New Chat
      </div>
      <div className="models">
        <label className="side-label">Do you want to use Operand AI?</label>
        <input
          type="checkbox"
          name="checkBoxIsUseOperandAI"
          onChange={(e) => handleInputChange(e, "checkBoxIsUseOperandAI",'checkbox')}
          value={userState.checkBoxIsUseOperandAI}
          // className="select-models"
          // onChange={onChangeHandler}
          // value={inputValue}
        />
        <label className="side-label">Enter base prompt</label>
        <input
          type="text"
          name="txtBasePrompt"
          className="select-models"
          onChange={(e) => handleInputChange(e, "txtBasePrompt",'input')}
          value={userState.txtBasePrompt}
          // onChange={onChangeHandler}
          // value={inputValue}
        />
        <label className="side-label">Enter Index Id</label>
        <input
          type="text"
          name="txtIndexId"
          className="select-models"
          onChange={(e) => handleInputChange(e, "txtIndexId",'input')}
          value={userState.txtIndexId}
          // onChange={onChangeHandler}
          // value={inputValue}
        />
        <label className="side-label">Model</label>
        <select
          // active if model is select is currentModel
          value={currentModel}
          className="select-models"
          onChange={(e) => {
            setCurrentModel(e.target.value)
          }}>
          {models && models.length ? models.map((model, index) => (
            <option
              key={model.id}
              value={model.id}>{model.id}</option>
          )) : <option
            key={"text-davinci-003"}
            value={"text-davinci-003"}>{"text-davinci-003"}</option>}
        </select>
        <Button
          text="Smart - Davinci"
          onClick={() => setCurrentModel("text-davinci-003")} />
        <Button
          text="Code - Crushman"
          onClick={() => setCurrentModel("code-crushman-001")} />
        <span className="info">
          The model parameter controls the engine used to generate the response. Davinci produces best results.
        </span>
        <label className="side-label" >Temperature</label>
        <input
          className="select-models"
          type="number"
          onChange={(e) => setTemperature(e.target.value)}
          min="0"
          max="1"
          step="0.1"
          value={temperature}
        />
        <Button
          text="0 - Logical"
          onClick={() => setTemperature(0)} />
        <Button
          text="0.5 - Balanced"
          onClick={() => setTemperature(0.5)} />
        <Button
          text="1 - Creative"
          onClick={() => setTemperature(1)} />
        <span className="info">
          The temperature parameter controls the randomness of the model. 0 is the most logical, 1 is the most creative.
        </span>

        <Button
          text="Logout"
          onClick={() => btnOnClickLogout()}
        />

        {/* <Button
          text="Login"
          onClick={() => btnOnClickLogin()}
        /> */}
      </div>
    </aside>
  );
};
const Button = ({ onClick, text }) =>
  <div
    className="button-picker"
    onClick={onClick}>
    {text}
  </div>

export default SideMenu